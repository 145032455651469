import { useCallback, useMemo } from 'react';
import { pick } from 'lodash';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { captureException } from '@sentry/react';

import { aggregateDetailsToEnum } from '../components/EmissionOverview/EmissionOverview';
import type {
  EmissionDetail_FromGetEmissionFragment,
  GetEmissionQuery,
  SegmentEmissions_FromGetEmissionFragment,
} from '../services/graphql/generated';
import { EmissionDetailTypeEnum, EmissionModeEnum, EmissionTypeEnum } from '../services/graphql/generated';
import type { ConsolidatedLeg, Segment } from '../components/EmissionTabs/types';
import type { EmissionFreightAggregatedDetails, LegEmissionsBreakdown } from '../components/RouteTimeline/types';
import type { ClarityContext } from '../components/EmissionTabs/EmissionContext/ClarityDetail/types';
import type { GetPublicEmissionQuery } from '../services/graphql-public/generated';

export function getEmissionsBreakdown(emission: { details: EmissionDetail_FromGetEmissionFragment[] }) {
  // Aggregating CO2e per type
  const co2ePerTypeArray = aggregateDetailsToEnum(emission.details, 'type', EmissionDetailTypeEnum);
  const co2ePerTypeObject = Object.fromEntries(co2ePerTypeArray.map((t) => [t.type, t.co2e]));
  const ttwEmissioms = emission.details.find((detail) => detail.type === EmissionDetailTypeEnum.Ttw);

  // Creating the emissions breakdown object
  const emissionsBreakdown: LegEmissionsBreakdown = {
    co2e: {
      [EmissionDetailTypeEnum.Wtt]: co2ePerTypeObject[EmissionDetailTypeEnum.Wtt],
      [EmissionDetailTypeEnum.Ttw]: co2ePerTypeObject[EmissionDetailTypeEnum.Ttw],
      [EmissionDetailTypeEnum.Other]: co2ePerTypeObject[EmissionDetailTypeEnum.Other],
    },
    ghg_ttw: pick(ttwEmissioms, ['co2', 'n2o', 'ch4']),
    other_pollutants_ttw: pick(ttwEmissioms, ['nox', 'nmhc', 'pm', 'so2']),
  };

  return emissionsBreakdown;
}

function getSegmentData(segment: SegmentEmissions_FromGetEmissionFragment): Segment {
  return {
    id: segment.id,
    sequenceNo: segment.sequence_no ?? 1,
    ...(segment.context_stringify ? { context: JSON.parse(segment.context_stringify) as ClarityContext } : {}),
    mode: segment.mode,
    characteristics: {
      is_ferry: segment?.freight?.route_details?.is_ferry ?? undefined,
      is_train: segment?.freight?.route_details?.is_train ?? undefined,
      country_code: segment?.freight?.route_details?.country_code_alpha_2 ?? undefined,
    },
  };
}

export const parseContext = (emission) => {
  try {
    const context = emission?.context ?? JSON.parse(emission?.context_stringify ?? '{}');
    return context?.emissions_calculation ?? context;
  } catch (error) {
    captureException(error);
    return {};
  }
};

const getEmissionDetails = (emission, type, context) => {
  let details;
  const fuelUsedAssumption = context?.emissions_calculation?.assumptions?.fuel_used;
  const fuelData = {};
  switch (type) {
    case EmissionTypeEnum.LastMile:
      details = {
        from: {
          address: emission.last_mile?.from_address,
          display_address: emission.last_mile?.from_display,
          coordinates: emission.last_mile?.from_coordinates,
          country_code: emission.last_mile?.from_country_code,
        },
        to: {
          address: emission.last_mile?.to_address,
          display_address: emission.last_mile?.to_display,
          coordinates: emission.last_mile?.to_coordinates,
          country_code: emission.last_mile?.to_country_code,
        },
        emissionDetails: {
          model: emission.last_mile?.model,
          make: emission.last_mile?.make,
          fuel_type: emission.last_mile?.fuel_type,
          vehicle: emission.last_mile?.vehicle,
          vehicle_category: emission.last_mile?.vehicle_category,
        },
        route: emission.last_mile?.route_details,
      };
      break;
    case EmissionTypeEnum.RideHailing:
      details = {
        from: {
          address: emission.ride_hailing?.from_address,
          display_address: emission.ride_hailing?.from_display,
          coordinates: emission.ride_hailing?.from_coordinates,
          country_code: emission.ride_hailing?.from_country_code,
        },
        to: {
          address: emission.ride_hailing?.to_address,
          display_address: emission.ride_hailing?.to_display,
          coordinates: emission.ride_hailing?.to_coordinates,
          country_code: emission.ride_hailing?.to_country_code,
        },
        emissionDetails: {
          vehicle: emission.ride_hailing?.vehicle,
          vehicle_category: emission.ride_hailing?.vehicle_category,
          make: emission.ride_hailing?.make,
          model: emission.ride_hailing?.model,
          fuel_type: emission.ride_hailing?.fuel_type,
        },
        route: emission.ride_hailing?.route_details,
      };
      break;
    case EmissionTypeEnum.Flights:
      details = {
        from: {
          code: emission.flights?.from_code,
          display_address: emission.flights?.from_display,
          coordinates: emission.flights?.from_coordinates,
          country_code: emission.flights?.from_country_code,
        },
        to: {
          code: emission.flights?.to_code,
          display_address: emission.flights?.to_display,
          coordinates: emission.flights?.to_coordinates,
          country_code: emission.flights?.to_country_code,
        },
        emissionDetails: {
          vehicle: 'AIRCRAFT',
          aircraft: emission.flights?.aircraft,
          flight_no: emission.flights?.flight_no,
          airline: emission.flights?.airline,
          cabin_class: emission.flights?.cabin_class,
        },
        route: emission.flights?.route_details,
      };
      break;
    case EmissionTypeEnum.Freight:
      details = {
        from: {
          address: emission.freight?.from_address,
          display_address: emission.freight?.from_display,
          coordinates: emission.freight?.from_coordinates,
          code: emission.freight?.from_code,
          country_code: emission.freight?.from_country_code,
        },
        to: {
          address: emission.freight?.to_address,
          display_address: emission.freight?.to_display,
          coordinates: emission.freight?.to_coordinates,
          code: emission.freight?.to_code,
          country_code: emission.freight?.to_country_code,
        },
        emissionDetails: {
          aircraft_code: emission.freight?.aircraft_code,
          carrier_code: emission.freight?.carrier_code,
          carrier_name: emission.freight?.carrier_name,
          flight_no: emission.freight?.flight_no,
          fuel_type: emission.freight?.fuel_type,
          fuel_identifier: emission.freight?.fuel_identifier,
          fuel_consumption: emission.freight?.fuel_consumption,
          fuel_consumption_unit: emission.freight?.fuel_consumption_unit,
          emission_standard: emission.freight?.emission_standard,
          load_factor: emission.freight?.load_factor,
          load_type: emission.freight?.load_type,
          empty_running: emission.freight?.empty_running,
          vehicle: emission.freight?.vehicle,
          vehicle_category: emission.freight?.vehicle_category,
          vehicle_code: emission.freight?.vehicle_code,
          vessel_id: emission.freight?.vessel_id,
          vessel_name: emission.freight?.vessel_name,
          cargo_type: emission.freight?.cargo_type,
          is_refrigerated: emission.freight?.is_refrigerated,
        },
        route: emission.freight?.route_details,
      };

      // Logistics sites are stationary so from/to coordinates should be the same
      if (emission.mode === EmissionModeEnum.LogisticsSite && details.from.coordinates && !details.to.coordinates) {
        details.to.coordinates = details.from.coordinates;
      }

      if (emission.mode === EmissionModeEnum.LogisticsSite) {
        delete context?.routing;
      }

      break;
    case EmissionTypeEnum.FreightExternallyCalculated:
      details = {
        from: {
          address: emission?.freight_externally_calculated?.from_display_address ?? (
            <FormattedMessage id="emissions.details.undisclosed" />
          ),
        },
        to: {
          address: emission?.freight_externally_calculated?.to_display_address ?? (
            <FormattedMessage id="emissions.details.undisclosed" />
          ),
        },
      };
      break;
    case EmissionTypeEnum.FreightAggregated:
      if (fuelUsedAssumption?.input_value && !fuelUsedAssumption.reason) {
        Object.assign(fuelData, {
          fuel_used: {
            value: emission.freight_aggregated?.fuel_used,
            unit: emission.freight_aggregated?.fuel_used_unit,
          },
        });
      } else {
        Object.assign(fuelData, {
          fuel_consumption: {
            value: emission.freight_aggregated?.fuel_consumption,
            unit: emission.freight_aggregated?.fuel_consumption_unit,
          },
        });
      }

      details = {
        isAggregated: true,
        endDate: emission.freight_aggregated?.end_date,
        emissionDetails: {
          vehicle_id: emission.freight_aggregated?.vehicle_id,
          vehicle_code: emission.freight_aggregated?.vehicle_code,
          fleet_id: emission.freight_aggregated?.fleet_id,
          fuel_type: emission.freight_aggregated?.fuel_type,
          ...fuelData,
          distance: {
            value: emission.freight_aggregated?.distance_km,
            unit: 'KM',
          },
          weight: {
            value: emission.freight_aggregated?.weight_kg,
            unit: 'KG',
          },
        } as EmissionFreightAggregatedDetails,
      };
      break;
    default:
      throw new Error(`emission details not implemented - unknown "emission.type"`);
  }

  return details;
};

export const useEmissionsLegs = ({
  emissionData,
}: {
  emissionData?:
    | GetEmissionQuery['emission']['emission_children'][number]
    | GetPublicEmissionQuery['public_emission']['emission_children'][number];
}): { legs: ConsolidatedLeg[] } => {
  const { formatMessage } = useIntl();

  const getLegData = useCallback(
    (emission) => {
      const context = parseContext(emission);
      const type = emission.type;
      const details = getEmissionDetails(emission, type, context);

      const stats = [
        {
          title: emission.co2e ? (
            <FormattedNumber
              value={emission.co2e}
              style="unit"
              unit="kilogram"
              unitDisplay="short"
              maximumFractionDigits={2}
            />
          ) : null,
          label: <FormattedMessage id="emissions.details.carbon.abbr" />,
          info: formatMessage({
            id: 'emissions.details.carbon.abbr',
          }),
        },
        {
          title: (
            <FormattedNumber
              value={emission.distance_km}
              style="unit"
              unit="kilometer"
              unitDisplay="short"
              maximumFractionDigits={2}
            />
          ),
          label: <FormattedMessage id="emissions.details.distance" />,
        },
      ];

      if (emission.mode === EmissionModeEnum.LogisticsSite) {
        stats.pop();
      }

      const emissionsBreakdown = getEmissionsBreakdown(emission);

      return {
        mode: emission.mode,
        date: emission.date,
        context,
        co2e: emission.co2e,
        stats,
        carriage: emission?.freight?.carriage,
        id: emission.id,
        public_id: emission.public_id,
        emissionsBreakdown,
        weightKg: emission?.weight_kg,
        distanceKm: emission?.distance_km,
        teu: emission?.teu,
        segments: emission.emission_children?.map((segmentEmission) => getSegmentData(segmentEmission)),
        sequenceNo: emission.sequence_no,
        level: emission?.level,
        shareToken: emission.share_token,
        accuracy: {
          data_quality_indicator: emission.quality,
          issues: emission.issues,
        },
        isSynthetic: emission.is_synthetic ?? false,
        ...details,
      };
    },
    [formatMessage],
  );

  const legs = useMemo(() => {
    if (!emissionData) {
      return [];
    }

    const sortedEmissionChildren = emissionData?.emission_children?.slice().sort((a, b) => {
      if (a?.sequence_no && b?.sequence_no) {
        return a.sequence_no - b.sequence_no;
      }
      return 0;
    });

    return sortedEmissionChildren?.length > 0
      ? sortedEmissionChildren.map((childEmission) => getLegData(childEmission))
      : [getLegData(emissionData)];
  }, [emissionData, getLegData]);

  return { legs };
};
